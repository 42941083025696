<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-card-title>
                    {{`${edit?'Update':'Add'}`}} Leave Category
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Title" v-model="leave_category.title" outlined dense>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.leave_category.title.$error">Title is required</span>
                        </v-col>
                        <v-col cols="12">
                            <label for="description">Description</label>
                            <ckeditor id="description" v-model="leave_category.description"
                                      :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.leave_category.description.$error">description is required</span>

                        </v-col>
                        <v-col cols="12">
                            <v-select outlined dense :items="applicable_for" v-model="leave_category.applicable_users"
                                      item-text="name"
                                      multiple
                                      item-value="value" label="Applicable Users">
                            </v-select>
                            <span class="text-danger" v-if="$v.leave_category.applicable_users.$error">Applicable Users is required</span>
                        </v-col>

                        <v-col cols="3">
                            <v-switch v-model="leave_category.is_active" label="Active"></v-switch>
                        </v-col>
                        <v-col cols="3">
                            <v-switch v-model="leave_category.auto_approval" label="Auto Approval"></v-switch>
                        </v-col>
                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn depressed @click="resetForm" color="mr-2" text dark medium >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    v-if="checkIsAccessible('leave-category', 'create') || checkIsAccessible('leave-category', 'edit')"
                                    depressed :loading="isBusy" @click="createOrUpdate"
                                       color="ml-2 btn btn-primary " medium>
                                    Save
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import LeaveCategoryService from "@/services/leave/leave-category/LeaveCategoryService";
    const leaveCategoryService = new LeaveCategoryService();
    export default {
        name: 'create-leave-category',
        validations: {
            leave_category: {
                title: {required},
                description: {required},
                applicable_users: {required},

            }
        },
        data() {
            return {
                academic_class: [],
                dialog: false,
                edit: false,
                isBusy: false,
                search: {
                    active: "active",
                    is_parent: 1,
                },
                applicable_for: [
                    {
                        name: 'Student',
                        value: 'student'
                    },
                    {
                        name: 'Teachers',
                        value: 'teacher'
                    }, {
                        name: 'Staff',
                        value: 'staff'
                    },
                    {
                        name: 'All',
                        value: 'all'
                    }
                ],
                editorConfig: {
                  versionCheck: false,
                  toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
                },
                leave_category: {
                    title: null,
                    description: null,
                    applicable_users: null,
                    is_active: true,
                    auto_approval: false
                },
            };
        },
        mounted() {

        },
        methods: {
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.__getSingle(id)
                }
                this.dialog = true;
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, __getSingle(id) {
                leaveCategoryService.show(id).then(response => {
                    this.leave_category = response.data.leave_category
                    this.leave_category.applicable_users = this.leave_category.applicable_users.split(',');
                })
            },

            // generateFd() {
            //     // console.log(this.leave_category)
            //     let fd = new FormData();
            //     for (let key in this.leave_category) {
            //         if (key == "is_active" && this.leave_category["is_active"]) {
            //             fd.append("is_active", this.leave_category ? 1 : 0);
            //         } else {
            //             if (this.leave_category[key] != null) {
            //                 fd.append(key, this.leave_category[key]);
            //             }
            //         }
            //     }
            //     return fd;
            // },
            createOrUpdate() {

                this.$v.leave_category.$touch();
                if (this.$v.leave_category.$error) {
                    setTimeout(() => {
                        this.$v.leave_category.$reset();
                    }, 3000);
                } else {
                    if (this.leave_category.applicable_users.length) {
                        this.leave_category.applicable_users = this.leave_category.applicable_users.join(',');
                    }
                    let fd = this.leave_category;
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                leaveCategoryService
                    .update(this.leave_category.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Updated successfully");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },


            __create(fd) {
                this.isBusy = true;
                leaveCategoryService
                    .store(fd)
                    .then((response) => {
                        // console.log(response)
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Created successfully");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.leave_category.$reset();
                this.edit = false;
                this.leave_category = {
                    title: null,
                    description: null,
                    applicable_users: null,
                    is_active: true,
                    auto_approval: false
                };
                this.hideModal();
                this.$emit("refresh_leave_category");
            }
        }
    };
</script>
