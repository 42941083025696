<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-state="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <!--begin: Wizard Form-->
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <h4>

                                    <span> Manage Leave Category</span>
                                </h4>
                                <router-link :to="{name:'dashboard'}">
                                    Dashboard
                                </router-link>
                                \ Leave Category

                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 text-right">
                                <a v-if="checkIsAccessible('leave-category', 'create')" @click="__add" class="btn btn-primary text-white mr-2">

                                    Add Leave Category
                                </a>

                            </div>
                        </div>
                        <div class="row">
                            <form @submit.prevent="__get" class="row mt-5 mb-5 bg-filter">
                                <div class="col-3">
                                    <v-text-field label="Title"
                                                  type="text"
                                                  dense
                                                  outlined
                                                  v-model="search.title"/>
                                </div>
                                <div class="col-4">
                                    <v-select label="Status" outlined dense :items="active_statuses" item-text="title"
                                              item-value="value"
                                              v-model="search.active"></v-select>
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-primary" @click="__get">Search</button>
                                </div>
                            </form>

                            <table class="table mt-3">
                                <thead class="text-left">
                                <th>Title</th>
                                <th>Applicable Users</th>
                                <th>Auto Approval</th>
                                <th>Action</th>
                                </thead>

                                <tr v-for="(leave_category, index) of leave_catgories" :key="leave_category.id">
                                    <td scope="row">
                                        <a href="#" @click="__edit(leave_category.id)" class="mr-2"> {{
                                            leave_category.title }}</a>
                                        <i class="fas fa-circle"
                                           :class="leave_category.is_active?'dot-active':'dot-inactive'"></i>
                                    </td>
                                    <td>
                                            <span v-if="leave_category.applicable_users">
                                                <template
                                                        v-if="getApplicable_users(leave_category.applicable_users).length">
                                                     <span class="badge badge-primary ml-2 text-capitalize"
                                                           v-for="(user,index) in getApplicable_users(leave_category.applicable_users)"
                                                           :key="index">
                                                        {{user}}
                                               </span>
                                                </template>
                                                <span class="badge badge-primary ml-2 text-capitalize" v-else>
                                                    {{leave_category.applicable_users}}
                                                </span>

                                            </span>
                                    </td>
                                    <td>
                                        <span class="badge"
                                              :class="leave_category.auto_approval?'badge-success':'badge-danger'">
                                            {{leave_category.auto_approval ? 'Yes' : 'No'}}
                                        </span>
                                    </td>

                                    <td>
                                        <b-dropdown
                                                size="sm"
                                                variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret
                                                right
                                                no-flip
                                        >
                                            <template v-slot:button-content>
                                                <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                                            </template>
                                            <!--begin::Navigation-->
                                            <div class="navi navi-hover ">

                                                <!--                                                  <b-dropdown-text tag="div" class="navi-item">-->
                                                <!--                                                    <a href="#" class="navi-link" @click="detail(leave_category.id)">-->
                                                <!--                                                            <span class="navi-icon">-->
                                                <!--                                                              <i class="fas fa-eye"></i>-->
                                                <!--                                                            </span>-->
                                                <!--                                                      <span class="navi-text"> View </span>-->
                                                <!--                                                    </a>-->
                                                <!--                                                  </b-dropdown-text>-->
                                                <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('leave-category', 'edit')" >
                                                    <a href="#" class="navi-link" @click="__edit(leave_category.id)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                                        <span class="navi-text"> Edit </span>
                                                    </a>
                                                </b-dropdown-text>

                                                <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('leave-category', 'delete')" >
                                                    <a href="#" class="navi-link"
                                                       @click="__delete(leave_category.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                                        <span class="navi-text">  Delete</span>
                                                    </a>
                                                </b-dropdown-text>

                                            </div>
                                            <!--end::Navigation-->
                                        </b-dropdown>

                                    </td>
                                </tr>

                            </table>
                        </div>
                        <div class="row">
                            <div class="col-12" v-if="total>perPage">
                                <b-pagination
                                        @input="__get"
                                        v-model="page"
                                        :total-rows="total"
                                        :per-page="perPage"
                                        first-number
                                        last-number
                                ></b-pagination>
                                <!--  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <create-and-update ref="leave_category" @refresh_leave_category="__get"></create-and-update>
        </div>
    </v-app>
</template>
<script>
    import CreateAndUpdate from "./CreateAndUpdate";
    import LeaveCategoryService from "@/services/leave/leave-category/LeaveCategoryService";
    const leaveCategoryService = new LeaveCategoryService();
    export default {
        name: "leave-category",
        display: "Table",
        order: 8,
        components: {
            CreateAndUpdate,
        },
        data() {
            return {
                active_statuses: [
                    {title: 'Active', value: "active"},
                    {title: 'In Active', value: "in_active"}
                ],
                leave_catgories: [],
                page: null,
                perPage: null,
                total: null,
                search: {
                    active: "active",
                    is_parent: "null"
                },
            };
        },
        mounted() {
            this.__get();
        },
        computed: {},
        methods: {
            getApplicable_users(users) {
                return users.split(',');
            },
            __get() {
                leaveCategoryService
                    .paginate(this.search)
                    .then(response => {
                        this.leave_catgories = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            __add() {
                this.$refs["leave_category"].showModal();
            },
            __edit(id) {
                this.$refs["leave_category"].showModal(id);
            },


            __delete(id) {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            leaveCategoryService
                                .delete(id)
                                .then(response => {
                                    this.$snotify.success('Sucessfully Deleted')
                                    this.__get();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            },


        }
    };
</script>
<style scoped>

</style>
